import {useEffect, useState} from 'react';
import {Link, Route} from 'react-router-dom';

import {Dialog} from 'primereact/dialog';

import {InvoiceService} from '../service/InvoiceService';

import {TraCuu} from './tra-cuu';

const invoiceService = new InvoiceService();

export const App = () => {

	const [displayAds, setDisplayAds] = useState('');

	useEffect(() => {
		invoiceService.getIntroduction().then(data => {
			if (data?.description?.trim()) {
				setDisplayAds(data.description);
			}
		}).catch(() => {
		});
	}, []);

	return (
		<div className="App">
			<div className="layout-topbar">
				<div className="flex align-items-center justify-content-between">
					<Link to="/" className="layout-topbar-logo">
						<img src={'/assets/3a-soft/logo.jpeg'} alt="logo"/>
					</Link>
				</div>
			</div>

			<div>
				<Route path="/" exact component={TraCuu}/>
			</div>

			<div className="layout-footer text-center text-white p-5">
				<b>CÔNG TY CỔ PHẦN GIẢI PHÁP PHẦN MỀM 3A VIỆT NAM</b>
				<p><i className="pi pi-map-marker mr-2"/>Địa chỉ: Tầng 3, Toà nhà hỗn hợp Vườn Đào, Lô E khu D1, P. Phú Thượng, Q. Tây Hồ, TP. Hà Nội, Việt Nam.</p>
				<p><i className="pi pi-phone mr-2"/><b>Điện thoại:</b> (+84-24) 3791 8098</p>
			</div>

			<Dialog visible={!!displayAds} style={{width: '40rem'}} onHide={() => setDisplayAds('')}>
				<div className="grid">
					<div className="col-12">
						<div dangerouslySetInnerHTML={{__html: displayAds}}></div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
